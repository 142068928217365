export const plataformaEnum = [
    "CADSEI",
    "FORMS",
    "NOVO SIGPLANI CREDITO FINANCEIRO",
    "FORMP&D",
    "Sigplani Módulo Inclusão Produtos",
    "Sigplani Módulo Inclusão Modelos",
    "NOVO SIGPLANI RDA",
    "SIGPPI",
    "FACTI"
];